import { AppSchema } from '../types'
import { templates } from './templates'

export const agnaiPresets = {
  agnai: {
    service: 'agnaistic',
    name: 'Default',

    swipesPerGeneration: 1,
    maxTokens: 512,
    maxContextLength: 2048,
    streamResponse: true,
    stopSequences: ['<|eot_id|>'],

    modelFormat: 'Llama3',
    useAdvancedPrompt: 'no-validation',
    gaslight: templates.EmanatorLlama,
    systemPrompt: '',
    ultimeJailbreak: '',
    ignoreCharacterSystemPrompt: false,
    ignoreCharacterUjb: false,

    memoryContextLimit: 512,
    memoryChatEmbedLimit: 512,
    memoryUserEmbedLimit: 512,
    memoryDepth: 10,

    temp: 1,
    dynatemp_range: 0,
    dynatemp_exponent: 1,
    smoothingFactor: 0,
    minP: 0.05,
    topP: 1,
    topK: 0,
    topA: 0,
    mirostatToggle: false,
    mirostatTau: 0,
    mirostatLR: 0,
    tailFreeSampling: 1,
    typicalP: 1,
    repetitionPenalty: 1,
    repetitionPenaltySlope: 0,
    repetitionPenaltyRange: 512,
    etaCutoff: 0,
    epsilonCutoff: 0,
    frequencyPenalty: 0,
    presencePenalty: 0,
    banEosToken: false,
    skipSpecialTokens: true,
    tempLast: true,
    useMaxContext: true,
    presetMode: 'advanced',
    registered: {
      agnaistic: {
        useRecommended: true,
      },
    },
  },
} satisfies Record<string, Partial<AppSchema.GenSettings>>
