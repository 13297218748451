import { EVENTS, events } from '../emitter'
import { storage } from '../shared/util'
import { createStore } from './create'
import { userStore } from './user'
import { AppSchema } from '/common/types'

export type Tag = string

const TAG_CACHE_KEY = 'agnai-tag-cache'

type TagOption = {
  tag: Tag
  count: number
}

type TagsState = {
  tags: TagOption[]
  search: string
  filter: Tag[]
  hidden: Tag[]
}

const defaultTags: Tag[] = ['nsfw', 'NSFW', 'imported', 'archived']
const defaultHidden: Tag[] = ['archived', 'nsfw', 'NSFW']

const initialState: TagsState = {
  tags: [
    { tag: 'NSFW', count: 0 },
    { tag: 'nsfw', count: 0 },
    { tag: 'imported', count: 0 },
    { tag: 'archived', count: 0 },
  ],
  search: '',
  filter: [],
  hidden: defaultHidden,
}

let restoredFromCache = false

export const tagStore = createStore<TagsState>(
  'tag',
  initialState
)(() => {
  const setTags = () => {
    const user = userStore()
    if (user.user?.storeTagsFilter) {
      tagStore.setState({
        filter: user.user.tagsFilter || [],
        hidden: user.user.tagsHidden || [],
      })
    }
  }

  events.on(EVENTS.loggedIn, () => {
    setTags()
  })

  events.on(EVENTS.init, () => {
    setTags()
  })

  return {
    setSearch(_, query: string, characters: AppSchema.Character[]) {
      const tagCounts = defaultTags.reduce(toEmptyTagCounts, {})

      for (const char of characters) {
        if (!char.tags) continue

        for (const tag of char.tags) {
          if (!tag) continue
          if (!tagCounts[tag]) tagCounts[tag] = 0
          tagCounts[tag]++
        }
      }

      const tags = Object.entries(tagCounts).map(toTagOption).sort(sortTags)
      if (query.length == 0) return { search: '', tags: tags }

      return {
        search: query,
        tags: tags.filter((tag) => tag.tag.toLowerCase().includes(query.toLowerCase().trim())),
      }
    },
    updateTags(prev, characters: AppSchema.Character[]) {
      const tagCounts = defaultTags.reduce(toEmptyTagCounts, {})

      for (const char of characters) {
        if (!char.tags) continue

        for (const tag of char.tags) {
          if (!tag) continue
          if (!tagCounts[tag]) tagCounts[tag] = 0
          tagCounts[tag]++
        }
      }

      const tags = Object.entries(tagCounts).map(toTagOption).sort(sortTags)

      let filter = prev.filter
      let hidden = prev.hidden

      if (!restoredFromCache) {
        restoredFromCache = true
        try {
          const cache = storage.localGetItem(TAG_CACHE_KEY)
          if (cache) {
            const { filter: f, hidden: h } = JSON.parse(cache)
            filter = f
            hidden = h
          }
        } catch (e) {
          console.warn('Failed to restore tags from local storage', e)
        }
      }

      return { tags, filter, hidden }
    },
    setDefault() {
      const next = { filter: [], hidden: defaultHidden }
      try {
        storage.localSetItem(TAG_CACHE_KEY, JSON.stringify(next))
        const user = userStore()
        if (user.user?.storeTagsFilter) {
          userStore.updatePartialConfig({
            tagsFilter: next.filter,
            tagsHidden: next.hidden,
          })
        }
      } catch (e) {
        console.warn('Failed to save tags in local storage', e)
      }
      return next
    },
    toggle(prev, tag: Tag, list?: boolean) {
      let next: Partial<TagsState>
      if (list) {
        next = prev.filter.includes(tag)
          ? { filter: prev.filter.filter((t) => t !== tag), hidden: prev.hidden }
          : { filter: prev.filter.concat(tag), hidden: prev.hidden }
      } else if (prev.filter.includes(tag)) {
        next = { filter: prev.filter.filter((t) => t !== tag), hidden: prev.hidden.concat(tag) }
      } else if (prev.hidden.includes(tag)) {
        next = { filter: prev.filter, hidden: prev.hidden.filter((t) => t !== tag) }
      } else {
        next = { filter: prev.filter.concat(tag), hidden: prev.hidden }
      }

      const user = userStore()
      if (user.user?.storeTagsFilter) {
        userStore.updatePartialConfig({
          tagsFilter: next.filter,
          tagsHidden: next.hidden,
        })
      }

      try {
        storage.localSetItem(TAG_CACHE_KEY, JSON.stringify(next))
      } catch (e) {
        console.warn('Failed to save tags in local storage', e)
      }
      return next
    },
  }
})

function toTagOption([tag, count]: [string, number]) {
  return { tag, count }
}

function toEmptyTagCounts(acc: Record<string, number>, tag: string) {
  return Object.assign(acc, { [tag]: 0 })
}

function sortTags(a: TagOption, b: TagOption) {
  if (a.tag === 'archived') return 1
  if (b.tag === 'archived') return -1
  if (a.count !== b.count) return b.count - a.count
  return a.tag.localeCompare(b.tag)
}
