import { A, useNavigate, useParams } from '@solidjs/router'
import { Component, createEffect, createMemo, createSignal, For, onMount, Show } from 'solid-js'
import { AllChat, characterStore, chatStore, tagStore, userStore } from '../../store'
import PageHeader from '../../shared/PageHeader'
import { Edit, Import, Plus, Trash, SortAsc, SortDesc, Pencil, Send } from 'lucide-solid'
import ImportChatModal from './ImportChat'
import { getAssetUrl, setComponentPageTitle, toDuration } from '../../shared/util'
import { ConfirmModal } from '../../shared/Modal'
import AvatarIcon, { CharacterAvatar } from '../../shared/AvatarIcon'
import { AppSchema } from '../../../common/types/schema'
import Select from '../../shared/Select'
import TextInput from '../../shared/TextInput'
import Button from '../../shared/Button'
import CharacterSelect from '../../shared/CharacterSelect'
import {
  ChatCharacter,
  ChatLine,
  SortDirection,
  SortType,
  getListCache,
  groupAndSort,
  saveListCache,
} from './util'
import Loading from '/web/shared/Loading'
import { ManualPaginate, usePagination } from '/web/shared/Paginate'
import { Page } from '/web/Layout'
import Tabs, { useTabs } from '/web/shared/Tabs'
import { markdown } from '/web/shared/markdown'
import { useEffect } from '/web/shared/hooks'
import { isLoggedIn } from '/web/store/api'
import { CharacterTags } from './components/CharacterCatView'

export const baseSortOptions = [
  { value: 'chat-updated', label: 'Chat Activity', kind: 'chat' },
  { value: 'bot-activity', label: 'Bot Activity', kind: 'chat' },
  { value: 'chat-created', label: 'Chat Created', kind: 'chat' },
  { value: 'character-name', label: 'Bot Name', kind: 'bot' },
  { value: 'character-created', label: 'Bot Created', kind: 'bot' },
]

const CharacterChats: Component = () => {
  const params = useParams()
  const cache = getListCache()

  const chars = characterStore((s) => ({
    list: s.characters.list,
    map: s.characters.list.reduce<Record<string, AppSchema.Character>>(
      (prev, curr) => Object.assign(prev, { [curr._id]: curr }),
      {}
    ),
    loaded: s.characters.loaded,
  }))

  const state = chatStore((s) => ({
    chats: s.allChats.map((chat) => ({
      _id: chat._id,
      name: chat.name,
      createdAt: chat.createdAt,
      updatedAt: chat.updatedAt,
      characterId: chat.characterId,
      characters: toChatListState(s.allChars.map, chat),
      messageCount: chat.messageCount,
      isPublic: chat.isPublic,
    })),
    chars: s.allChars.map,
    publicChats: s.publicChats.map((chat) => ({
      _id: chat._id,
      name: chat.name,
      createdAt: chat.createdAt,
      updatedAt: chat.updatedAt,
      characterId: chat.characterId,
      characters: toChatListState(s.allChars.map, chat),
      messageCount: chat.messageCount,
      isPublic: chat.isPublic,
    })),
  }))

  const user = userStore()

  const sortOptions = createMemo(() => {
    const opts = baseSortOptions.slice()
    const hasCounts = state.chats.some((c) => !!c.messageCount)

    if (hasCounts) {
      opts.push({ value: 'chat-count', label: 'Chat Counts', kind: 'chat' })
    }

    return opts
  })

  const nav = useNavigate()
  const [search, setSearch] = createSignal('')
  const [charId, setCharId] = createSignal<string | undefined>(params.id)
  const [showImport, setImport] = createSignal(false)
  const [sortField, setSortField] = createSignal(cache.sort.field)
  const [sortDirection, setSortDirection] = createSignal(cache.sort.direction)
  const [selectedChar, setSelectedChar] = createSignal<AppSchema.Character>()
  const [hasPublicChats, setHasPublicChats] = createSignal(false)

  const tabs = useTabs(['All Chats', 'Public Chats'])

  createEffect(() => {
    if (!params.id) {
      setComponentPageTitle(`Chats`)
      return
    }

    const char = characters().find((c) => c._id === params.id)
    setComponentPageTitle(char ? `${char.name} chats` : 'Chats')
  })

  createEffect(() => {
    const next = {
      sort: {
        field: sortField(),
        direction: sortDirection(),
      },
    }

    saveListCache(next)
  })

  const updateSelectedChar = () => {
    setHasPublicChats(
      state.publicChats.some((chat) =>
        chat.characters.some((character) => character._id === selectedChar()?._id)
      )
    )
  }

  createEffect(() => {
    if (!charId()) return
    if (sortField() === 'character-name' || sortField() === 'character-created') {
      setSortField('chat-updated')
    }
    setSelectedChar(chars.list.find((c) => c._id === charId()))
    updateSelectedChar()
  })

  createEffect(() => {
    if (selectedChar()) characterStore.setViewingProfile(selectedChar()!.userId)
  })

  const chats = createMemo(() => {
    const filterCharId = charId()

    return state.chats.filter((chat) => {
      if (filterCharId && !chat.characters.some((c) => c._id === filterCharId)) return false
      const trimmed = search().trim().toLowerCase()
      if (!trimmed) return true
      if (chat.name.toLowerCase().includes(trimmed)) return true
      if (chat.characters.some((c) => c.name.toLowerCase().includes(trimmed))) return true
      if (chat.characters.some((c) => c.description.toLowerCase().includes(trimmed))) return true
      return false
    })
  })

  const publicChats = createMemo(() => {
    const filterCharId = charId()

    return state.publicChats.filter((chat) => {
      if (filterCharId && !chat.characters.some((c) => c._id === filterCharId)) return false
      const trimmed = search().trim().toLowerCase()
      if (!trimmed) return true
      if (chat.name.toLowerCase().includes(trimmed)) return true
      if (chat.characters.some((c) => c.name.toLowerCase().includes(trimmed))) return true
      if (chat.characters.some((c) => c.description.toLowerCase().includes(trimmed))) return true
      return false
    })
  })

  const pager = usePagination({
    name: 'chat-list',
    items: chats,
    pageSize: 48,
  })

  const publicPager = usePagination({
    name: 'public-chat-list',
    items: publicChats,
    pageSize: 48,
  })

  onMount(() => {
    if (!chars.loaded) {
      characterStore.getCharacters()
    }

    chatStore.getAllChats()
    // chatStore.getPublicChats()
  })

  const characterState = characterStore()

  const tags = tagStore((s) => ({ filter: s.filter, hidden: s.hidden }))

  const characters = createMemo(() => {
    return chars.list
      .slice()
      .filter((ch) => tags.filter.length === 0 || ch.tags?.some((t) => tags.filter.includes(t)))
      .filter((ch) => !ch.tags || !ch.tags.some((t) => tags.hidden.includes(t)))
  })

  const Options = () => (
    <>
      <button
        class={`btn-primary w-fit items-center justify-center py-2`}
        onClick={() => setImport(true)}
      >
        <Import /> <span class="hidden sm:inline">Import Chat</span>
      </button>
      <Show when={!!params.id && selectedChar()?.userId === user.user?._id}>
        <button
          class={`btn-primary w-fit items-center justify-center py-2`}
          onClick={() => nav(`/character/${params.id}/edit`)}
        >
          <Edit /> <span class="hidden sm:inline">Edit Character</span>
        </button>
      </Show>
      <button
        class={`btn-primary w-fit items-center justify-center py-2`}
        onClick={() => nav(`/chats/create/${params.id || ''}`)}
      >
        <Plus /> <span class="hidden sm:inline">New Chat</span>
      </button>
    </>
  )

  return (
    <Page class="flex flex-col gap-2">
      <Show when={selectedChar()}>
        <PageHeader
          title={
            <div class="flex w-full justify-between">
              <div>{selectedChar()?.name}</div>
            </div>
          }
        />
        <div class="flex flex-row items-center gap-2">
          <div class="flex w-1/2 flex-col items-center gap-2">
            <Show when={selectedChar()?.avatar}>
              <img
                src={getAssetUrl(selectedChar()?.avatar!)}
                class="ml-auto mr-auto h-fit w-2/3 object-cover"
              />
            </Show>
            <Show when={!selectedChar()?.avatar}>
              <AvatarIcon format={{ corners: 'none', size: 'max3xl' }} />
            </Show>
            <div class="flex justify-center gap-2 text-base">
              <Options />
            </div>
            <Show when={selectedChar()!.tags && selectedChar()!.tags!.length > 0}>
              <div class="w-[28rem] py-2 text-center">
                <CharacterTags char={selectedChar()!} />
              </div>
            </Show>
          </div>
          <div class="flex flex-col gap-2 p-2">
            <div
              class="rendered-markdown bg-900 rounded-b-md "
              innerHTML={markdown.makeHtml(selectedChar()?.description || '')}
            />
            <p class="text-sm italic text-gray-400">
              Owner{' '}
              <A href={`/profile/${characterState.viewingProfile?.userId}`} class="link">
                {characterState.viewingProfile?.handle}
              </A>
            </p>
            <p class="text-sm italic text-gray-400">
              Created on {new Date(selectedChar()?.createdAt!).toLocaleString()}{' '}
              {selectedChar()!.creator!
                ? `by 
            ${selectedChar()?.creator}`
                : ''}
            </p>
            <p class="text-sm italic text-gray-400">
              Lasted updated {new Date(selectedChar()?.updatedAt!).toLocaleString()}
            </p>
          </div>
        </div>
      </Show>
      <PageHeader
        title={
          <div class="flex w-full justify-between">
            <div>Chats</div>
          </div>
        }
      />

      <Show when={hasPublicChats() || !selectedChar()}>
        <div class="mb-2">
          <Tabs tabs={tabs.tabs} selected={tabs.selected} select={tabs.select} />
        </div>
      </Show>

      <div class="mb-2 flex justify-between">
        <div class="flex flex-wrap gap-1">
          <div>
            <TextInput
              fieldName="search"
              placeholder="Search..."
              onKeyUp={(ev) => setSearch(ev.currentTarget.value)}
            />
          </div>

          <CharacterSelect
            class="w-48"
            fieldName="char"
            items={characters()}
            emptyLabel="All Characters"
            value={charId()}
            onChange={(char) => {
              setCharId(char?._id)
              setSelectedChar(char)
              updateSelectedChar()
              if (!hasPublicChats()) tabs.select(0)
            }}
          />

          <div>
            <Button
              schema="secondary"
              class="rounded-xl"
              onClick={() => {
                const next = sortDirection() === 'asc' ? 'desc' : 'asc'
                setSortDirection(next as SortDirection)
              }}
            >
              {sortDirection() === 'asc' ? <SortAsc /> : <SortDesc />}
            </Button>
          </div>

          <Select
            class="bg-[var(--bg-600)]"
            fieldName="sortBy"
            items={sortOptions().filter((opt) => (charId() ? opt.kind === 'chat' : true))}
            value={sortField()}
            onChange={(next) => setSortField(next.value as SortType)}
          />
        </div>
      </div>

      <Show when={tabs.selected() === 0}>
        <Show
          when={pager.items().length}
          fallback={<NoChats character={characters().find((c) => c._id === params.id)?.name} />}
        >
          <Chats
            allChars={state.chars}
            chats={pager.items()}
            chars={characters()}
            sortField={sortField()}
            sortDirection={sortDirection()}
            charId={charId()}
            showPencil
          />
        </Show>

        <div class="flex justify-center pb-5 pt-2">
          <ManualPaginate pager={pager} />
        </div>
      </Show>

      <Show when={tabs.selected() === 1}>
        <Show
          when={publicPager.items().length}
          fallback={<NoChats character={characters().find((c) => c._id === params.id)?.name} />}
        >
          <Chats
            allChars={state.chars}
            chats={publicPager.items()}
            chars={characters()}
            sortField={sortField()}
            sortDirection={sortDirection()}
            charId={charId()}
          />
        </Show>

        <div class="flex justify-center pb-5 pt-2">
          <ManualPaginate pager={publicPager} />
        </div>
      </Show>

      <Show when={selectedChar()}>
        <Comments selectedChar={selectedChar} user={user.user!} />
      </Show>

      <ImportChatModal
        show={showImport()}
        close={() => setImport(false)}
        char={characters().find((c) => c._id === charId())}
      />
    </Page>
  )
}
const Comment: Component<{
  selectedChar: () => AppSchema.Character | undefined
  commentId: string
  content: string
  date: string
  userId: string
}> = (props) => {
  const state = characterStore()
  const user = userStore()

  const [profile, setProfile] = createSignal(state.commentProfiles.map[props.userId])

  // autism
  createEffect(() => {
    if (Object.keys(state.commentProfiles.map).includes(props.userId))
      setProfile(state.commentProfiles.map[props.userId])
  })

  return (
    <div>
      <Show when={profile()}>
        <div class="flex flex-row gap-2 rounded-md bg-[var(--bg-700)] px-2 pt-3 text-base leading-none">
          <AvatarIcon
            avatarUrl={profile().avatar}
            format={{ size: 'md', corners: 'circle' }}
            openable
          />
          <div class="flex w-full flex-col">
            <div class="flex w-full flex-row items-center gap-2 text-center">
              <div class="overflow-hidden whitespace-nowrap font-bold">{profile().handle}</div>
              <p class="text-sm italic text-gray-400">{new Date(props.date).toLocaleString()}</p>
              <Show when={user.user?._id === props.userId}>
                <Button
                  schema="clear"
                  onClick={() => {
                    characterStore.deleteUserComment(props.selectedChar()?._id!, props.commentId)
                  }}
                  class="ml-auto justify-end"
                  disabled={!props.selectedChar()?.isPublic}
                >
                  <Trash
                    class="icon-button"
                    size={18}
                    classList={{ 'cursor-not-allowed': !props.selectedChar()?.isPublic }}
                  />
                </Button>
              </Show>
            </div>
            <div
              class="rendered-markdown pb-2 leading-normal"
              innerHTML={markdown.makeHtml(props.content)}
            />
          </div>
        </div>
      </Show>
    </div>
  )
}

const Comments: Component<{
  selectedChar: () => AppSchema.Character | undefined
  user: AppSchema.User
}> = (props) => {
  const [text, setText] = createSignal('')

  createEffect(() => {
    characterStore.getCommentProfiles()
  })

  const isDisabled =
    (!props.user?.sub && !props.user?.manualSub) ||
    !props.selectedChar()?.isPublic ||
    props.user.restricted ||
    false

  const comments = createMemo(() => {
    return props.selectedChar()?.userComments || []
  })

  const pager = usePagination({
    name: 'comments-list',
    items: comments,
    pageSize: 10,
  })

  return (
    <>
      <PageHeader title={<div>Comments</div>} />
      <div class="flex w-full flex-col justify-between gap-4">
        <Show when={isLoggedIn()}>
          <div class="flex flex-row items-center justify-center rounded-md bg-[var(--bg-800)] text-base">
            <TextInput
              fieldName="chatInput"
              isMultiline
              spellcheck
              placeholder={
                props.user.restricted
                  ? 'You are restricted'
                  : !props.user?.sub && !props.user?.manualSub
                  ? 'subscribe to leave comments'
                  : 'leave a comment'
              }
              parentClass="flex w-full"
              class="input-bar rounded-r-none hover:bg-[var(--bg-800)] active:bg-[var(--bg-800)]"
              classList={{ 'cursor-not-allowed': isDisabled }}
              value={text()}
              disabled={isDisabled}
              onInput={(ev) => {
                setText(ev.currentTarget.value)
              }}
            />
            <Button
              schema="clear"
              onClick={() => {
                characterStore.leaveUserComment(props.selectedChar()?._id!, text())
                setText('')
                characterStore.getCommentProfiles()
              }}
              disabled={isDisabled}
            >
              <Send
                class="icon-button"
                size={24}
                classList={{ 'cursor-not-allowed': isDisabled, 'hover:text-500': isDisabled }}
              />
            </Button>
          </div>
        </Show>

        <Show when={pager.items().length}>
          <For each={pager.items()}>
            {({ id, content, date, userId }) => (
              <Comment
                selectedChar={props.selectedChar}
                commentId={id}
                content={content}
                date={date}
                userId={userId}
              />
            )}
          </For>
        </Show>

        <div class="flex justify-center pb-5 pt-2">
          <ManualPaginate pager={pager} />
        </div>
      </div>
    </>
  )
}

export const Chats: Component<{
  allChars: Record<string, AppSchema.Character>
  chats: ChatLine[]
  chars: AppSchema.Character[]
  sortField: SortType
  sortDirection: SortDirection
  charId?: string
  showPencil?: boolean
}> = (props) => {
  const [showDelete, setDelete] = createSignal('')
  const [editingChatName, setEditingChatName] = createSignal('')
  const [editing, setEditing] = createSignal(false)
  const [oldChatName, setOldChatName] = createSignal('')

  let ref: HTMLTextAreaElement

  const groups = createMemo(() => {
    const chars = props.charId ? props.chars.filter((ch) => ch._id === props.charId) : props.chars

    return groupAndSort(chars, props.chats, props.sortField, props.sortDirection)
  })

  const confirmDelete = () => {
    chatStore.deleteChat(showDelete(), () => setDelete(''))
  }

  const nav = useNavigate()

  const clickFunction = () => {
    if (editing()) {
      ref.blur()
      setEditingChatName('')
      setEditing(false)
    }
  }

  const escFunction = (ev: KeyboardEvent) => {
    if (editing() && ev.key === 'Escape') {
      ev.preventDefault()
      setEditingChatName('')
      setEditing(false)
      ref.blur()
      ref.value = oldChatName()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', clickFunction, false)
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('mousedown', clickFunction, false)
      document.removeEventListener('keydown', escFunction, false)
    }
  })

  return (
    <div class="flex flex-col gap-2">
      <For each={groups()}>
        {({ char, chats }) => (
          <>
            <div class="flex flex-col gap-2">
              <Show when={char}>
                <div class="font-bold">{char!.name}</div>
              </Show>
              <Show when={chats.length === 0}>
                <div>No conversations</div>
              </Show>
              <For each={chats}>
                {(chat) => (
                  <div class="flex w-full justify-between gap-2 rounded-lg bg-[var(--bg-800)] p-1 hover:bg-[var(--bg-700)]">
                    <div
                      class="flex w-10/12 cursor-pointer gap-2 sm:w-11/12"
                      onClick={() => {
                        if (document.activeElement !== ref) nav(`/chat/${chat._id}`)
                      }}
                    >
                      <div class="ml-4 flex items-center">
                        <div class="relative flex-shrink-0">
                          <For each={chat.characters.slice(0, 3).reverse()}>
                            {(ch, i) => {
                              const positionStyle = getAvatarPositionStyle(chat, i)
                              if (positionStyle === undefined) return

                              return (
                                <div
                                  class={`absolute top-1/2 -translate-y-1/2 transform ${positionStyle}`}
                                >
                                  <CharacterAvatar
                                    char={props.allChars[ch._id]}
                                    surround
                                    zoom={1.75}
                                    format={{ size: 'md', corners: 'circle' }}
                                  />
                                </div>
                              )
                            }}
                          </For>
                        </div>
                      </div>

                      <div class="flex max-w-[90%] flex-col justify-center gap-0 pl-14">
                        <div class="overflow-hidden text-ellipsis whitespace-nowrap font-bold leading-5">
                          {chat.characters.map((c) => c.name).join(', ')}
                        </div>
                        <div class="flex flex-row items-center gap-2 overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-4">
                          <Show when={chat.name && editingChatName() !== chat._id}>
                            <span>{chat.name || ''} </span>
                          </Show>
                          <Show when={editingChatName() === chat._id}>
                            <TextInput
                              fieldName="chatName"
                              value={chat.name || ''}
                              ref={ref! as any}
                              onChange={(ev) => {
                                chatStore.editChat(
                                  chat._id,
                                  { name: ev.currentTarget.value },
                                  false
                                )
                                setEditingChatName('')
                              }}
                              // parentClass="h-full"
                              class="px-0"
                            />
                          </Show>
                          <span class="text-xs italic text-[var(--text-600)]">
                            {toDuration(new Date(chat.updatedAt)) || 0} ago
                            <Show when={chat.messageCount !== undefined}>
                              &nbsp;({chat.messageCount})
                            </Show>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-row items-center gap-2 px-2">
                      <Show when={props.showPencil}>
                        <Pencil
                          onClick={() => {
                            setTimeout(() => setEditing(true), 100)
                            setEditingChatName(chat._id)
                            setOldChatName(chat.name)
                            ref.focus()
                          }}
                          size={20}
                          class="icon-button"
                        />
                      </Show>
                      <Trash onClick={() => setDelete(chat._id)} size={20} class="icon-button" />
                    </div>
                  </div>
                )}
              </For>
            </div>
          </>
        )}
      </For>
      <ConfirmModal
        show={!!showDelete()}
        close={() => setDelete('')}
        confirm={confirmDelete}
        message="Are you sure wish to delete the conversation?"
      />
    </div>
  )
}

const NoChats: Component<{ character?: string }> = (props) => {
  const state = chatStore()
  return (
    <Show
      when={state.loaded}
      fallback={
        <div class="flex w-full justify-center">
          <Loading />
        </div>
      }
    >
      <div class="flex w-full justify-center text-xl">
        <div>
          <Show when={!props.character}>You have no conversations yet.</Show>
          <Show when={props.character}>
            You have no conversations with <i>{props.character}</i>.
          </Show>
        </div>
      </div>
    </Show>
  )
}

export default CharacterChats

function getAvatarPositionStyle(chat: ChatLine, i: () => number) {
  if (chat.characters.length === 1) {
    return ''
  }
  if (chat.characters.length === 2) {
    return i() === 0 ? 'translate-x-1/4 ' : '-translate-x-1/4 '
  }
  if (chat.characters.length >= 3) {
    return i() === 0 ? 'translate-x-1/4 ' : i() === 1 ? '' : '-translate-x-1/4 '
  }
  return
}

function toCharacterIds(characters?: Record<string, boolean>) {
  if (!characters) return []

  const ids: string[] = []
  for (const [id, enabled] of Object.entries(characters)) {
    if (enabled) ids.push(id)
  }
  return ids
}

export function toChatListState(chars: Record<string, AppSchema.Character>, chat: AllChat) {
  const charIds = [chat.characterId].concat(toCharacterIds(chat.characters))

  const seen = new Set<string>()
  const rows: ChatCharacter[] = []
  for (const id of charIds) {
    if (seen.has(id)) continue
    seen.add(id)
    const char = chars[id]
    if (!char) {
      rows.push({ _id: '', name: 'Unknown', description: '', avatar: '' })
      continue
    }

    rows.push({
      _id: char._id,
      name: char.name,
      description: char.description || '',
      avatar: char.avatar,
    })
  }

  return rows
}
